<template>
	<div class="list-activity">
		<table-list
			:loading="loading"
			:items="events"
			:is-sticky="true"
		>
			<template slot="header">
				<span
					:class="`events-header-item events-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<div
					:class="`events-item events-item--${key}`"
					:key="`item_${key}`"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item,key))"
				>
				</div>
			</template>
		</table-list>
	</div>
</template>

<script>
import { get } from 'lodash'

import TableList from '@/components/TableList'

export default {
	name: 'ListActivity',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		events: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		isLoading: false,
		columns: {
			dateCreated: 'Date',
			event: 'Event',
			name: 'Name',
			email: 'Email',
		},
	}),
	methods: {
		get,
		transform(key, value) {
			switch (key) {
				case 'dateCreated':
					return `<time datetime="${value}" title="${this.$options.filters.date(value, 'MM/dd/yyyy HH:mm:ss')}">${this.$options.filters.date(value, 'MM/dd/yy hh:mmaaa')}</time>`
			}
			return value
		}
	}

}
</script>

<style scoped lang="scss">
.list-activity {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			background-color: modules.color_('background');
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: minmax(100px, 0.75fr) repeat(3, minmax(100px, 1fr));

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {
			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.events-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.events-item {
		color: modules.color_('text', 'dark');
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&:first-of-type {
			@include modules.media-query('phone') {
				font-weight: 600;
			}
		}

		&--pending {
			&.events-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.events-item--status {
				color: modules.color_('alert', 'critical')
			}
		}

		&--actions {
			@include modules.media-query('phone') {
				text-align: right;
			}
		}
	}
}
</style>

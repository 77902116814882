<template>
	<nav class="tab-bar" role="tablist" :class="{'tab-bar--toggled': isToggled}" @click="isToggled = !isToggled">
		<div class="tab-bar__items">
			<template v-for="(item, key) in items">
				<slot
					name="item"
					:idx="key"
					:item="item"
				></slot>
			</template>
		</div>
		<div class="tab-bar__actions" v-if="$slots.actions">
			<slot name="actions"></slot>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'tabBar',
	data: () => ({
		isToggled: false,
	}),
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
	},
}
</script>

<style scoped lang="scss">
.tab-bar {
	@include modules.fontSize('18px');
	border-bottom: 1px solid modules.color_('border');
	display: flex;
	min-height: 3em;
	width: 100%;

	&__items {
		align-items: center;
		display: flex;
		flex: 1;
		width: 100%;
		overflow-x: auto;
	}

	&__item {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
		align-items: center;
		align-self: stretch;
		color: modules.color_('text', 'light');
		display: flex;
		justify-content: center;
		position: relative;
		text-decoration: none;
		transition: color 150ms linear;
		white-space: nowrap;

		&:after {
			background-color: modules.color_('text', 'medium');
			bottom: 0;
			content: ' ';
			display: block;
			height: 3px;
			left: 0;
			position: absolute;
			transform: scaleX(0);
			transform-origin: 50% 50%;
			transition: transform 250ms modules.$ease-out,
				background-color 150ms linear;
			width: 100%;
			will-change: transform, background-color;
		}

		&:hover {
			color: modules.color_('primary');
		}

		&--active {
			color: modules.color_('primary', 'light');

			&:after {
				background-color: modules.color_('primary', 'light');
				transform: scaleX(1);
			}
		}
	}

	&__actions {
		flex: 1;
	}
}
</style>

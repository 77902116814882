<template>
	<div class="card" :class="{'card--loading':loading}">
		<div class="card__loader" v-if="loading">
			<loading-spinner />
		</div>
		<div class="card__header" v-if="$slots.header">
			<slot name="header" />
		</div>
		<slot />
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'card',
	components: {
		LoadingSpinner,
	},
	props: {
		loading: Boolean,
	},
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.card {
	$card: &;

	// @include elevation.elevation(20, rgba(0, 0, 0, 0.15), -0.1);
	background-color: modules.color_('background');
	box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
	// border-radius: modules.$border-radius;
	position: relative;

	>>> &__title {
		@include modules.gutter('margin-left');
		@include modules.gutter('margin-bottom', 0.3);
		@include modules.gutter('margin-top', 0);
		@include modules.gutter('padding', 0);
		@include modules.fontSize(18px);
		font-weight: bold;

		&--single {
			@include modules.gutter('margin-top', 0.5);
		}
	}

	>>> &__subtitle {
		@include modules.gutter('margin-left');
		@include modules.gutter('margin-bottom', 0.5);
		@include modules.gutter('margin-top', 0);
		@include modules.gutter('padding', 0);
		font-size: 14px;
		font-weight: 400;
	}

	>>> &__fields {
		@include modules.gutter('margin');
		border-top: 1px solid modules.color_('border');
	}

	>>> &__item {
		@include modules.gutter('padding', 0.5);
		// @include gutter('padding-left');
		// @include gutter('padding-right');
		align-items: center;
		border-bottom: 1px solid modules.color_('border');
		color: modules.color_('text');
		display: flex;
		justify-content: space-between;
		transition: background-color 150ms modules.$ease-standard;

		a:not(.mdc-button) {
			color: modules.color_('text');
			text-decoration: none;
		}

		&-actions {
			opacity: 0;
			transition: opacity 150ms modules.$ease-standard;
		}

		&--no-hover {
			#{$card}__item-actions {
				opacity: 1;
			}
		}

		&:not(#{$card}__item--no-hover) {
			&:hover {
				background-color: modules.color_('background', 'open');

				#{$card}__item-actions {
					opacity: 1;
				}
			}
		}

		&-label {
			color: modules.color_('text');
			font-weight: 600;
		}
	}

	&__loader {
		align-items: center;
		background-color: rgba(225, 225, 225, 0.35);
		border-radius: modules.$border-radius;
		display: flex;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 11;
	}

	&__header {
		@include modules.gutter('padding');
		border-bottom: 1px solid modules.color_('border', 'light');

		> div {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	&__actions {
		display: flex;
	}

	&__title {
		@include modules.fontSize(20px);
		line-height: 1;
		margin: 0;
	}

	>>> &__subtitle {
		@include modules.fontSize(16px);
		font-weight: 400;
		line-height: 1;
		margin: 0;
	}
}
</style>

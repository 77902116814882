<template>
	<div
		class="upload-field"
		:class="{'upload-field--over': dragOver}"
		@dragover.prevent="dragOver = true"
		@dragenter.prevent="dragOver = true"
		@dragend.prevent="dragOver = false"
		@dragleave.prevent="dragOver = false"
		@drop.prevent="dragOver = false"
	>
		<file-upload
			class="upload-field__dropzone"
			:drop="true"
			:multiple="multiple"
			ref="upload"
			v-model="files"
			v-bind="attrs"
			v-on="listeners"
			v-if="multiple || (!multiple && files.length < 1)"
		>
			<div class="upload-field__body">
				<i class="upload-field__icon"><ion-icon name="cloud-upload"></ion-icon></i>
				<p class="upload-field__content">
					Drag and drop here or click to browse
				</p>
			</div>
		</file-upload>

		<div class="upload-field__files" v-if="files.length">
			<h5 class="upload-field__files-title">Upload Queue</h5>
			<div
				class="upload-field__file"
				:key="file.id"
				v-for="file in files"
			>
				<div class="upload-field__file-name">
					{{file.name}}
					<small>({{file.size | bytes}})</small>
				</div>
				<a class="upload-field__file-action" @click.prevent="removeFile(file)" title="Remove File from Queue">
					<ion-icon name="trash"></ion-icon>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import FileUpload from 'vue-upload-component'

export default {
	name: 'uploadField',
	components: {
		FileUpload,
	},
	props: {
		multiple: false,
	},
	data: () => ({
		files: [],
		dragOver: false,
	}),
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
			}
		},
	},
	methods: {
		input(event) {
			this.$emit('input', event)
		},
		removeFile(file) {
			const idx = this.files.findIndex(a => a.id === file.id)
			if (idx > -1) {
				this.files.splice(idx, 1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.upload-field {
	$field: &;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 	380px;
	transition: border 150ms modules.$ease-standard;

	&__dropzone {
		align-items: center;
		border-radius: modules.$border-radius;
		border: 1px dashed modules.color_('border','dark');
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		min-height: 220px;
		width: 100%;

		#{$field}:hover &,
		#{$field}--over & {
				border-color: darken(modules.color_('border', 'dark'), 10);

			#{$field}__icon {
				color:  darken(modules.color_('border', 'dark'), 10);
			}
		}

		#{$field}:focus-within & {
			border-color: modules.color_('primary');
		}
	}

	&__icon {
		color: modules.color_('text','xlight');
		font-size: 64px;
		transition: color 150ms modules.$ease-standard;

		#{$field}:hover &,
		#{$field}--over & {
			color: modules.color_('text');
		}
	}

	&__content {
		font-size: 15px;

		em {
			color: modules.color_('alert','error');
			text-emphasis: none;
		}
	}

	&__files {
		@include modules.gutter('margin-top', 0.5);
		width: 100%;

		&-title {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&__file {
		@include modules.gutter('padding', 0.25);
		border-bottom: 1px solid modules.color_('border');
		align-items: center;
		display: flex;
		justify-content: space-between;

		&:first-of-type {
			border-top: 1px solid modules.color_('border');
		}

		&-name {
			flex: 1;
			font-weight: 600;

			small {
				font-weight: normal;
			}
		}

		&-action {
			cursor: pointer;
			text-decoration: none;

			.material-icons {
				font-size: 16px;
			}
		}

	}

	>>> .file-uploads {
		cursor: pointer;
	}
}
</style>

<template>
	<div class="partial partial--opportunies users">
		<div class="partial__heading">
			<h2 class="partial__title">Users</h2>
		</div>
		<card class="partial__body">
			<form class="partial__search" @submit.prevent.stop="submitForm(fields)">
				<text-field class="form__field form__field--search" label="Search Users" compact="true" v-model="fields.name" type="search" autocomplete="off" @input="checkSearch">
					<span slot="pre"><ion-icon name="search"></ion-icon></span>
				</text-field>
			</form>
			<list-users :users="users" :loading="isLoading" />
			<pagination :limit="limit" :count="count" />
		</card>
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListUsers from '@/components/ListUsers'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import TextField from '@/components/TextField'

export default {
	name: 'PartialUsers',
	components: {
		Card,
		ListUsers,
		Modal,
		Pagination,
		TextField,
	},
	data: () => ({
		isLoading: false,
		fromQuery: null,
		limit: 25,
		fields: {},
	}),
	computed: {
		users() {
			return this.$store.getters['user/users'].map(user => ({ ...user, nameEmail: [user.name, user.email].join(`<br />`)}) )
			return this.$store.getters['user/users']
		},
		count() {
			return this.users.length
		},
		modalVisible() {
			return this.$route.name == 'user'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'users', query: this.fromQuery })
		},
		async submitForm(fields) {
			if(fields.name) {
				await this.$router.push({ query: { key: 'keywords.name', op: 'array-contains', value: fields.name } })
			} else {
				await this.$router.push({ query: {} })
			}
		},
		async checkSearch(val) {
			if (!val) {
				await this.$router.push({ query: {} })
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				if (from && from.name == 'users' && to.name == 'user') return this.fromQuery = from.query
				if (from && from.name == 'users'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return

				this.isLoading = true

				this.$store.dispatch('user/list', { ...to.query, p: to.query.p || 1, limit: this.limit })
					.then(() => {

					})
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.then(() => {
						this.isLoading = false
					})

				if (to.query && to.query.key) {
					this.fields[to.query.key.replace(/keywords\./i, '')] = to.query.value
				}
			},
		},
	},
	metaInfo: () => ({
		title: 'Users',
	}),
}
</script>

<style scoped lang="scss">
</style>

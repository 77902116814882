<template>
	<div class="partial partial--opportunies organizations">
		<div class="partial__heading">
			<h2 class="partial__title">Organizations</h2>
		</div>
		<card class="partial__body">
			<form class="partial__search" @submit.prevent.stop="submitForm(fields)">
				<text-field class="form__field form__field--search" label="Search Organizations" compact="true" v-model="fields.name" type="search" autocomplete="off" @input="checkSearch">
					<span slot="pre"><ion-icon name="search"></ion-icon></span>
				</text-field>
			</form>
			<list-organizations :organizations="organizations" :loading="isLoading" />
			<pagination :limit="limit" :count="count" />
		</card>
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListOrganizations from '@/components/ListOrganizations'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import TextField from '@/components/TextField'

export default {
	name: 'PartialOrganizations',
	components: {
		Card,
		ListOrganizations,
		Modal,
		Pagination,
		TextField,
	},
	data: () => ({
		isLoading: false,
		fromQuery: null,
		limit: 25,
		fields: {},
	}),
	computed: {
		organizations() {
			return this.$store.getters['organization/organizations']
		},
		count() {
			return this.organizations.length
		},
		modalVisible() {
			return this.$route.name == 'organization'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'organizations', query: this.fromQuery })
		},
		async submitForm(fields) {
			if(fields.name) {
				await this.$router.push({ query: { key: 'keywords.name', op: 'array-contains', value: fields.name } })
			} else {
				await this.$router.push({ query: {} })
			}
		},
		async checkSearch(val) {
			if (!val) {
				await this.$router.push({ query: {} })
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				if (from && from.name == 'organizations' && to.name == 'organization') return this.fromQuery = from.query
				if (from && from.name == 'organizations'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return

				this.isLoading = true

				this.$store.dispatch('organization/list', { ...to.query, p: to.query.p || 1, limit: this.limit })
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.then(() => {

					})
					.then(() => {
						this.isLoading = false
					})

				if (to.query && to.query.key) {
					this.fields[to.query.key.replace(/keywords\./i, '')] = to.query.value
				}
			},
		},
	},
	metaInfo: () => ({
		title: 'Organizations',
	}),
}
</script>

<style scoped lang="scss">
</style>

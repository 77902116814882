<template>
	<div class="switch-field">
		<div class="mdc-switch" :class="{'mdc-switch--disabled': disabled, 'mdc-switch--checked': value}">
			<div class="mdc-switch__track"></div>
			<div class="mdc-switch__thumb-underlay">
				<div class="mdc-switch__thumb"></div>
				<input type="checkbox"
					:id="id"
					:checked="value"
					:disabled="disabled"
					class="mdc-switch__native-control"
					role="switch"
					aria-checked="false"
					v-bind="attrs"
					v-on="listeners"
				>
			</div>
		</div>
		<label class="button button--small" :class="{'button--disabled': disabled}" :for="id">{{ label }}</label>
	</div>
</template>

<script>
import { v4 } from 'uuid'
import { MDCSwitch } from '@material/switch'

export default {
	name: 'SwitchField',
	data: () => ({
		switch: null,
	}),
	props: {
		disabled: Boolean,
		id: {
			type: String,
			default: v4(),
		},
		label: String,
		value: Boolean,
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
			}
		},
		attrs() {
			const { ...attrs } = this.$attrs
			return attrs
		},
	},
	mounted() {
		this.switch = new MDCSwitch(this.$el.querySelector('.mdc-switch'))
	},
	methods: {
		input (evt) {
			this.$emit('input', evt.target.checked)
		},
	},
}
</script>

<style scoped lang="scss">
@use "@material/switch";

@include switch.core-styles;

.switch-field {
	align-items: center;
	display: flex;
	flex-direction: row;

	.mdc-switch {
		@include switch.toggled-on-color(modules.color_('primary'));

		&--disabled {
			cursor: not-allowed;
		}
	}

	.button {
		&--disabled {
			cursor: not-allowed;
		}
	}

	label {
		min-width: 90px;

	}
	&.no-label {
		label {
			display: none;
		}
	}
}
</style>

<template>
	<div class="list-opportunities" :class="{'list-opportunities--user':!isAdmin }">
		<table-list
			:loading="loading"
			:items="opportunities"
			:is-sticky="true"
		>
			<template slot="header">
				<span
					:class="[`opportunities-header-item opportunities-header-item--${key}`, {'sortable':col.sort == true, 'sorted':orderBy == key, 'down':orderBy == key && order == 'desc'}]"
					:key="key"
					v-for="(col, key) in columns"
					@click="col.sort ? $emit('sort',key) : $emit()"
				>{{ col.label }}</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`opportunities-item opportunities-item--${key}`"
					:key="`item_${key}`"
					:to="{ name: 'opportunity', params: { opportunityId: item.opportunityId }}"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
					:title="transform(key, get(item, key)) | stripHtml"
					@click.native="$event.stopImmediatePropagation()"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { get } from 'lodash'

import TableList from '@/components/TableList'

export default {
	name: 'ListOpportunities',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		opportunities: {
			type: Array,
			default: () => ([]),
		},
		orderBy: String,
		order: String,
	},
	data: () => ({
		fields: {},
	}),
	computed: {
		isAdmin() {
			return this.$store.getters['user/isAdmin']
		},
		columns() {
			let columns = {
				name: {label: 'Name', sort: true},
				org: {label: 'Organization', sort: false},
				createdByName: {label: 'Lender', sort: true},
				loanAmount: {label: 'Amount', sort: true},
				feeHedge: {label: 'Hedge', sort: true},
				spread: {label: 'Spread', sort: false},
				maturity: {label: 'Mat.', sort: false},
				existingLoan: { label: 'Type', sort: false},
				dateCreated: {label: 'Created', sort: true},
				dateModified: {label: 'Updated', sort: true},
			}

			if (!this.isAdmin) {
				delete columns.client
				delete columns.createdByName
				delete columns.modifiedByName
			}
			return columns
		},
	},
	methods: {
		get,
		transform(key, value) {
			if (key == 'existingLoan') {
				return value ? `Existing` : `New`
			}
			if (!value) return
			switch(key) {
				case 'loanAmount':
					return this.$options.filters.currency((value || 0), false, 0)
				case 'feeHedge':
					return this.$options.filters.basisPoints(value)
				case 'client':
					const org = this.$options.filters.organization(value.organization)
					return `<span title="${org}">${org}</span>`
				case 'loanIndex':
					return this.$options.filters.enums(value, 'LoanIndex')
				case 'dateCreated':
				case 'dateModified':
					return `<time datetime="${value}" title="${this.$options.filters.date(value, 'Pp')}">${this.$options.filters.date(value)}</time>`
				default:
					return value
					break
			}

			return value
		},
	},
}
</script>

<style scoped lang="scss">
.list-opportunities {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	&--user {
		::v-deep .table-list {
			&__item,
			&__header {
				// grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
				grid-template-columns: repeat(2, minmax(165px, 1fr)) minmax(120px, 1fr) repeat(4, minmax(40px, 0.75fr)) repeat(2, minmax(100px, 0.9fr)) !important;
			}
		}
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(3, minmax(165px, 1fr)) minmax(120px, 1fr) repeat(4, minmax(40px, 0.75fr)) repeat(2, minmax(100px, 0.9fr));
			padding: 0 0.5em;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			align-items: flex-end;
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {
			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.opportunities-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.0em 0.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}

		&.sortable {
			cursor: pointer;
			color: modules.color_('primary','dark');
		}

		&.sorted {
			text-decoration: underline;

			&:after {
				color: modules.color_('text','medium');
				content: '\25B2';
				display: inline-block;
				margin-left: 0.5em;
			}

			&.down {
				&:after {
					content: '\25BC';
				}
			}
		}
	}

	.opportunities-item {
		color: modules.color_('text', 'dark');
		overflow: hidden;
		padding: 1.0em 0.5em;
		text-decoration: none;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&:first-of-type {
			@include modules.media-query('phone') {
				font-weight: 600;
			}
		}

		&--pending {
			&.organizations-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.organizations-item--status {
				color: modules.color_('alert', 'critical')
			}
		}
	}
}
</style>

<template>
	<form class="form form--tools form-tools" @submit.prevent.stop="submitForm">
		<!-- <div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div> -->
		<tab-bar
			:items="reportPages"
			class="form-tools__nav-tabs">
			<template slot-scope="{ item, idx }" slot="item">
				<router-link
					class="tab-bar__item a"
					:class="{'tab-bar__item--active': search.legacyType == item.link}"
					:id="`nav_tab_${idx}`"
					:to="item.link"
					>
					{{ item.label }}
				</router-link>
			</template>
		</tab-bar>

		<div class="form-section form-tools__totals">
			<dl class="form-tools__total">
				<dt>Total Notional</dt>
				<dd>{{ totals.swDealNotlAmt_sum || 0 | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Daily Notional Change</dt>
				<dd>{{ totals.notionalNChange | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Market Value</dt>
				<dd>{{ totals.swMarketVal_sum || 0 | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total DV01</dt>
				<dd>{{ totals.o1value_sum || 0 | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Notional (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.swDealNotlAmt_sum) + Number(totalsB.swDealNotlAmt_sum)) || 0 | currency">{{ totalsB.swDealNotlAmt_sum || 0 | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Daily Notional Change (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.notionalNChange) + Number(totals.notionalBChange)) || 0 | currency">{{ totals.notionalBChange | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Market Value (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.swMarketVal_sum) + Number(totalsB.swMarketVal_sum)) || 0 | currency">{{ totalsB.swMarketVal_sum || 0 | currency }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total DV01 (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.o1value_sum) + Number(totalsB.o1value_sum)) || 0 | currency">{{ totalsB.o1value_sum || 0 | currency }}</dd>
			</dl>
		</div>

		<div class="form-section form-section--search">
			<div class="form-section--bval-controls">
				<a class="button" @click="isSearching = !isSearching">
					<ion-icon :name="(isSearching) ? 'close' : 'search'" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Search</span>
				</a>
				<bval-reports-menu  v-show="!isSearching" @generateReport="generateReport" />
			</div>
			<div class="form-row form-row--col-4 search" :class="{'search--open':(isSearching)}">
				<text-field class="form__field" v-model="search.borrower" label="Custom ID" />
				<text-field class="form__field" v-model="search.customer" label="Bank" />
				<text-field class="form__field" v-model="search.swCounterParty" label="CounterParty" />
				<text-field class="form__field" v-model="search.swPayCpn" label="Pay Coupon" />
				<text-field class="form__field" v-model="search.payFltRateIdx" label="Flt Index" />
				<text-field class="form__field" v-model="search.swPaySpread" label="Flt Spread" />
				<text-field class="form__field" v-model="search.swEffDt" label="Effective Date" placeholder="mm/dd/yyyy" />
				<div class="form__actions">
					<button type="submit" class="button button--primary button--unelevated form__action" :disabled="isLoading">Search</button>
					<button type="cancel" class="button button--secondary" @click="clearQuery()">clear</button>
				</div>
			</div>
		</div>

		<tab-bar
			:items="legacyTypes"
			class="form-tools__tabs">
			<template slot-scope="{ item, idx }" slot="item">
				<div
					class="tab-bar__item a"
					:class="{'tab-bar__item--active': search.legacyType == item.link}"
					:id="`type_tab_${idx}`"
					@click="search.legacyType = item.link; submitForm()"
					>
					{{ item.label }}
				</div>
			</template>
		</tab-bar>

		<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
			<div class="mdc-data-table__progress-indicator">
				<div class="mdc-data-table__scrim"></div>
				<loading-line :loading="true" />
			</div>
			<div class="mdc-data-table__table-container">
				<table class="mdc-data-table__table" aria-label="BVAL">
					<thead>
						<tr class="mdc-data-table__header-row">
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">
								Custom ID
							</th>
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Bank</th>
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">CounterParty</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Current Not.</th>
							<!-- <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Next Not.</th> -->
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Fixed</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Spread</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Start</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">End</th>
						</tr>
					</thead>
					<tbody class="mdc-data-table__content">
						<tr class="mdc-data-table__row" v-for="(row, key) of trades" :key="key" @click.prevent="toggleRow(key)" :class="{'mdc-data-table__row--selected':isToggled.includes(key), 'row--is-b': isB(row)}">
							<th class="mdc-data-table__cell mdc-data-table__cell--name" scope="row">{{ row.borrower }}</th>
							<td class="mdc-data-table__cell mdc-data-table__cell--name">{{ row.customer }}</td>
							<td class="mdc-data-table__cell">{{ row.swCounterParty }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swDealNotlAmt | currency }}</td>
							<!-- <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.notional | currency }}</td> -->
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (row.fixedReceived * 100) | percentage }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.spread * 100 | percentage }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swEffDt | date }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.maturity | date }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--details">
								<div class="mdc-data-table__edit">
									<router-link :to="{name: 'tradeEdit', params: { tab: $route.params.tab, counterparty: row.swCounterParty, tradeId: row.tradeId, }}" class="button button--primary" @click.native="$event.stopImmediatePropagation()">Edit</router-link>
								</div>

								<span>Swap ID</span>
								<strong>{{ row.swapId }}</strong>

								<span>Swap Type</span>
								<strong>{{ row.swType }}</strong>

								<span>Next Pay Date</span>
								<strong>{{ row.swPayNxtCpnDt | date }}</strong>

								<span>Market Coupon</span>
								<strong>{{ row.swParCpn * 100 | number(false, 0) }}</strong>

								<span>Current Exposure</span>
								<strong>{{ row.swMarketVal > 0 ? row.swMarketVal : 0 | currency }}</strong>

								<span>Counter Party</span>
								<strong>{{ row.swCounterParty }}</strong>

								<span>BB PV01</span>
								<strong>{{ row.o1value | currency }}</strong>

								<span>Leverage</span>
								<strong>{{ row.swPayLeverage * 100 }}</strong>

								<span>VaR (Trade Log)</span>
								<strong>{{ row.var | currency }}</strong>

								<span>Effective Date</span>
								<strong>{{ row.swEffDt | date }}</strong>

								<span>BB DV01</span>
								<strong>{{ row.swEqvBpv | currency }}</strong>

								<span>Associated Bank</span>
								<strong>{{ row.customer }}</strong>

								<span>Current Exp (App VaR)</span>
								<strong>{{ row.varToValue | percentage }}</strong>

								<span>Maturity</span>
								<strong>{{ row.tradeMaturity | date }}</strong>

								<span>Net Accr Int</span>
								<strong>{{ row.swNetAccInt | currency }}</strong>

								<span>Derivitives</span>
								<strong>{{ row.swCounterParty }}</strong>

								<span>Credit Exp (Dodd Frank)</span>
								<strong>{{ (row.creditExpDodd || 0) | currency }}</strong>

								<span>Currency</span>
								<strong>{{ row.crncy }}</strong>

								<span>Pay Freq</span>
								<strong>{{ row.swPayFreq }}</strong>

								<span>Fair Value</span>
								<strong>{{ row.swMarketVal | currency }}</strong>

								<span>Exposure to Corps</span>
								<strong>{{ row.exposureToCorps | currency }}</strong>

								<span>Pay Coupon</span>
								<strong>{{ row.swPayCpn * 100 | number(false, 3)}}</strong>

								<span>Flt Index</span>
								<strong>{{ row.payFltRateIdx }}</strong>

								<span>Clearing</span>
								<strong>{{ row.clearing }}</strong>

								<span>Exposure Rank 1</span>
								<strong>{{ row.rank1 }}</strong>

								<span>Pay Type</span>
								<strong>{{ row.swPayCpnType }}</strong>

								<span>Flt Spread</span>
								<strong>{{ row.swPaySpread }}</strong>

								<span>BD Trade</span>
								<strong>{{ row.bd }}</strong>

								<span>Exposure Rank 2</span>
								<strong>{{ row.rank2 }}</strong>

								<span>Receive Coupon</span>
								<strong>{{ row.swRecCpn * 100 | number(false, 3) }}</strong>

								<span>Market Value</span>
								<strong>{{ row.swMarketVal | currency }}</strong>

								<span>BD Trade ID</span>
								<strong>{{ row.bdTradeId }}</strong>

								<span>Downstream Positive Mkt Val</span>
								<strong>{{ row.downstreamPositive | currency }}</strong>

								<span>Receive Type</span>
								<strong>{{ row.swRecCpnType }}</strong>

								<span>DV01</span>
								<strong>{{ row.o1value | currency }}</strong>

								<span>Bank 2</span>
								<strong>{{ row.bank2 }}</strong>

								<span>Downstream Negative Mkt Val</span>
								<strong>{{ row.downstreamNegative | currency }}</strong>

								<span>Next Notional</span>
								<strong>{{ row.swDealNotlAmt | currency }}</strong>

								<span>Day Count Pay</span>
								<strong>{{ row.swPayDayCnt }}</strong>

								<span>CME / LCH</span>
								<strong>{{ row.clearingHouse }}</strong>

								<span>Years to Maturity</span>
								<strong>{{ row.yearFrac | number(false, 4) }}</strong>

								<span>Current Notional</span>
								<strong>{{ row.notional | currency }}</strong>

								<span>Day Count Rec</span>
								<strong>{{ row.swRecDayCnt }}</strong>

								<span>Market Wire ID</span>
								<strong>{{ row.marketWireId }}</strong>

								<span>Clearing House ID</span>
								<strong>{{ row.clearingHouseId }}</strong>

								<span>CounterParty ID</span>
								<strong>{{ row.counterPartyId }}</strong>

								<span>Legacy</span>
								<strong>{{ row.legacyType | enums('LegacyType') }}</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="mdc-data-table__progress-indicator mdc-data-table__progress-indicator--bottom">
				<div class="mdc-data-table__scrim"></div>
				<loading-line :loading="true" />
			</div>

			<div class="mdc-data-table__pagination">
				<select-field v-model="params.limit" label="Per Page" :options="limitOptions" @input="changeLimit" />
				<pagination :limit="params.limit" :count="count" />
			</div>
		</div>

		<div class="form-section">
			<div class="form-tools__details">
				<dl class="form-tools__meta">
					<dt>Total Records</dt>
					<dd v-if="meta && meta.total_records">
						{{ meta.total_records | number(false, 0) }}
					</dd>
				</dl>
				<dl class="form-tools__meta">
					<dt>Last Modified</dt>
					<dd v-if="meta && meta.last_modified_time">
						{{ meta.last_modified_time.value | date('MM/dd/yy hh:mma')}}
					</dd>
				</dl>
			</div>
		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<button class="button button--primary form__action" type="button" @click="importData()" :disabled="isLoading">(dev) Import BVAL Data</button>
			</div>
		</div>

	</form>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'
import Pagination from '@/components/Pagination'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import BvalReportsMenu from '@/components/BvalReportsMenu'
import { MDCDataTable } from '@material/data-table'
import papaparse from 'papaparse'
import { format } from 'date-fns'
import { forceDownload } from '@/lib/utils'
import { LegacyType } from '@/lib/enums'

import TabBar from '@/components/TabBar'

export default {
	name: 'FormToolsBval',
	components: {
		LoadingLine,
		LoadingSpinner,
		Pagination,
		SelectField,
		TabBar,
		TextField,
		BvalReportsMenu
	},
	data: () => ({
		isLoading: false,
		table: undefined,
		results: {
			updated: [],
			errors: [],
		},
		isToggled: [],
		params: {
			limit: 100,
		},
		limitOptions: [
			{
				value: 100,
				label: 100,
			},
			{
				value: 250,
				label: 250,
			},
			{
				value: 500,
				label: 500,
			},
			{
				value: 1000,
				label: 1000,
			},
		],
		isSearching: false,
	}),
	computed: {
		bvalData() {
			return this.$store.getters['tools/bvalData']
		},
		trades() {
			return this.bvalData.trades
		},
		totals() {
			return this.$store.getters['tools/totals']
		},
		totalsB() {
			return this.$store.getters['tools/totalsB']
		},
		meta() {
			return this.bvalData.meta
		},
		count() {
			return this.trades.length
		},
		search() {
			let s = {}
			const { fields, values } = this.$route.query
			if (fields && fields.length) {
				fields.forEach((field, i) => {
					s[field] = values[i]
				})
			}
			return s
		},
		hasSearch() {
			const { fields, values, } = this.$route.query
			return !!((values || []).filter(v => v)).length
		},
		legacyTypes() {
			let types = [
				{
					label: `All Trades`,
					link: '',
				},
				{
					label: `ARC Trades`,
					link: `none`,
				},
			]

			for (const t in LegacyType) {
				let suffix = t == 'fmv' ? `` : ` (Legacy)`
				types.push({
					label: `${LegacyType[t]}${suffix}`,
					link: t,
				})
			}

			return types
		},
		reportPages() {
			return [
				{
					label: `Trade Log`,
					link: {
						name: 'tools',
						params: {
							tab: 'bval',
						},
					},
				},
				{
					label: `Executive Summary`,
					link: {
						name: 'bvalReport',
						params: {
							tab: 'bval',
							report: 'executive-summary',
						},
					},
				},
				{
					label: `Daily QC Report`,
					link: {
						name: 'bvalReport',
						params: {
							tab: 'bval',
							report: 'daily-qc',
						},
					},
				},
			]
		},
	},
	mounted() {
		this.table = new MDCDataTable(document.querySelector('.mdc-data-table'))
	},
	methods: {
		async importData() {
			this.isLoading = true
			try {
				let results = await this.$store.dispatch(`tools/importBvalData`)
				this.$notice(`BVAL data imported from ${results}.`)
				this.queryData()
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		async queryData() {
			this.isLoading = true
			const { p, limit } = this.$route.query
			try {
				await this.$store.dispatch(`tools/queryBvalData`, { p, limit })
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		hasB(trade) {
			if (trade.swCounterParty.match(/(B)$/)) return false
		},
		isB(trade) {
			if (trade.swCounterParty.match(/(B)$/)) return true
			return false
		},
		async generateReport(reportType) {
			this.isLoading = true
			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, reportType)
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			switch (reportType) {
				case 'dailyQc': {
					results.data.unshift({
						date: `Date`,
						notional: `Total Notional`,
						mtm: `MTM`,
						mtmFmv: `MTM incl. FMV loans`,
						dv01: `DV'01`,
						notionalChange: `Notional Chg`,
						mtmChange: `MTM Chg`,
						mtmFmvChange: `MTM less FMV loans - Chg`,
						dv01Change: `DV'01 Chg`,
					})
					let output = papaparse.unparse(results.data, {
						header: false,
					})

					const _dt = format((new Date()), 'yyyy-MM-dd', (new Date()))
					forceDownload(output, `${_dt} - Daily QC Check.csv`)
					break
				}
				case 'executiveSummary': {
					let reportData = [{
						rank: `Rank`,
						id: `ID`,
						approvedVar: `Approved VaR`,
						space1:``,
						name: `Institution`,
						totalNotional: `Notional`,
						totalMtm: `MTM`,
						totalDv01: `DV01`,
						totalPfe: `PFE`,
						totalExposure: `gr curr exp`,
						netGrossRatio: `Net Gross Ratio`,
						totalCem: `CEM`,
						space2: ``,
						callReportLabel: `CALL REPORT DATA`,
						callReportValue: ``,
						callReportExtra: ``,
					}]

					const { callReport, summary, top10, } = results.data
					const callData = [
						{
							callReportLabel: `Positive FV`,
							callReportValue: callReport.positiveFv,
						},
						{
							callReportLabel: `Negative FV`,
							callReportValue: callReport.negativeFv,
						},
						{
							callReportLabel: `Swaps where bank pays fixed`,
							callReportValue: callReport.bankFixed,
							callReportExtra: `mkt val by tenor`,
						},
						{
							callReportLabel: ``,
						},
						{
							callReportLabel: `Maturity < 1YR`,
							callReportValue: callReport.maturityLt1Notional,
							callReportExtra: callReport.maturityLt1Exposure,
						},
						{
							callReportLabel: `Maturity 1-5YR`,
							callReportValue: callReport.maturityLt5Notional,
							callReportExtra: callReport.maturityLt5Exposure,
						},
						{
							callReportLabel: `Maturity > 5YR`,
							callReportValue: callReport.maturityGt5Notional,
							callReportExtra: callReport.maturityGt5Exposure,
						},
						{
							callReportLabel: ``,
						},
						{
							callReportLabel: ``,
							callReportValue: `Net Current Credit Exposure`,
						},
						{
							callReportLabel: `w/ Bank and Securities Firms`,
							callReportValue: callReport.creditExposureBank,
						},
						{
							callReportLabel: `w/ Corporations and Other CPs`,
							callReportValue: callReport.creditExposureCorp,
						},
						{
							callReportLabel: `w/ ClearingHouses`,
							callReportValue: callReport.creditExposureClearing,
						},
					]

					const keyLength = Math.max(top10.length, summary.length, callData.length)
					console.log({ keyLength })

					for (let i = 0; i < keyLength; i++) {
						let row = {}
						if (top10[i]) {
							row = {...row, ...top10[i]}
						}
						if (summary[i]) {
							row = {...row, ...summary[i]}
						}
						if (callData[i]) {
							row = {...row, ...callData[i]}
						}
						reportData.push(row)
					}

					// reportData = [...reportData, ...top10, ...summary, ...callData,]

					let output = papaparse.unparse(reportData, {
						header: false,
					})

					const _dt = format((new Date()), 'yyyy-MM-dd', (new Date()))
					forceDownload(output, `${_dt} - Executive Summary.csv`)

					break
				}
			}
		},
		toggleRow(key) {
			let idx = this.isToggled.findIndex(a => a == key)
			if (idx > -1) {
				this.isToggled.splice(idx, 1)
			} else {
				this.isToggled.push(key)
			}
		},
		changeLimit(val) {
			let query = {
				...this.$route.query,
				...{
					limit: val,
				}
			}
			console.log({ query, })
			this.$router.push({ query })
		},
		submitForm() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			this.$router.push({ query: { fields, values }})
			this.isToggled = []
		},
		clearQuery() {
			this.search = {}
			this.$router.push({ query: {fields: null, values: null}})
		},
	},
	metaInfo: () => ({
		title: `BVAL`,
	}),
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				// Prevent reloads
				if (to.name == 'tradeEdit') return
				if (from && JSON.stringify(to.query || {}) == JSON.stringify(from.query || {}) ) return

				this.isLoading = true
				const { p, limit, fields, values, } = to.query

				try {
					await this.$store.dispatch(`tools/queryBvalData`, { p, limit, fields, values, })
				} catch (error) {
					this.$notice(`ERROR: ${error}`)
				} finally {
					this.isLoading = false
				}

			},
		},
	},
}
</script>

<style scoped lang="scss">
@use "@material/data-table/data-table";
$density-config: (
  height: (
    maximum: 48px,
    default: 48px,
    minimum: 24px,
  ),
);
@include data-table.core-styles;
@include data-table.theme-baseline;

.mdc-data-table {
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	$table: &;

	&__table {
		@include modules.fontSize(13px);
		width: 100%;
		white-space: normal;
	}

	&__header {
		&-cell {
			align-items: center;
			display: flex;

			&--numeric {
				justify-content: flex-end;
			}

		}
	}

	&__row,
	&__header-row {
		display: grid;
		grid-template-columns: repeat(2, 12rem) 1fr 1fr 1fr 5rem 1fr 1fr;
	}

	&__row {
		cursor: pointer;

		&--selected {
			background-color: modules.color_('background', 'light');
			font-weight: 500;
		}

		&.row--is-b {
			border-top-color: rgba(0, 0, 0, 0.10);
			border-top-style: dashed;
		}
	}

	&__cell {
		@include modules.fontSize(13px);
		align-items: center;
		display: flex;
		height: 48px;

		#{$table}__row--selected & {
			font-weight: 500;
		}

		&--numeric {
			justify-content: flex-end;
		}

		&--details {
			@include modules.fontSize(12px);
			background-color: #fff;
			border-top: 1px solid modules.color_('border', 'light') !important;
			color: #212B36;
			cursor: auto;
			align-items: flex-start;
			grid-column: 1 / -1;
			justify-content: flex-start;
			display: none;
			height: auto;
			line-height: 1.2;
			padding: 1rem 2rem;
			row-gap: 1rem;

			.mdc-data-table__row--selected & {
				display: grid;
				grid-template-columns: repeat(4, 1.65fr 1.55fr);
			}

			span {
				font-weight: normal;

				&:not(:empty) {
					&:after {
						content: ':';
					}
				}
			}

			strong {
				font-weight: 500;
				margin-right: 1rem;

				&:empty {
					&:after {
						content: '(empty)';
						font-weight: normal;
						opacity: 0.7;
					}
				}
			}
		}

		&--name {
			line-height: 1.1;
			overflow: hidden;
		}
	}

	&__edit {
		align-items: center;
		display: flex;
		grid-column: 1/ -1;
		justify-content: flex-end;
	}

	&__pagination {
		@include modules.gutter('padding');
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		::v-deep .pagination {
			@include modules.gutter('padding', 0);
		}
	}

	&__progress-indicator {
		&--bottom {
			bottom: 0;
		}
	}
}

.form-section {
	&--search {
		@include modules.gutter('padding-top', .5);
		@include modules.gutter('padding-bottom', .5);
	}

	&--bval-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}


.search {
	max-height: 0;
	overflow: hidden;
	transition: all 250ms modules.$ease-standard;

	&--open {
		max-height: 700px;
		overflow: visible;
		padding-top: 20px;
		padding-bottom: 10px;
	}
}

.form-tools {
	&__totals {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		display: grid;
		justify-items: center;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 2rem;
		width: 100%;
	}

	&__total {
		text-align: center;
		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		dt {
			@include modules.fontSize(13px);
			font-weight: bold;
			text-transform: uppercase;
		}

		dd {
			@include modules.fontSize(23px);
			line-height: 1.4;
		}
	}

	&__details {
		@include modules.gutter('grid-column-gap');
		display: grid;
		grid-template-columns: auto auto;
		justify-content: flex-end;
	}

	&__meta {
		@include modules.fontSize(12px);
		display: flex;
		text-align: center;
		dt,
		dd {
			margin: 0;
			padding: 0 0.15rem;
		}

		dt:after {
			content: ':';
		}


	}

	&__tabs {
		border-top: 1px solid rgba(0, 0, 0, 0.06);
	}
}
</style>

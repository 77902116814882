<template>
	<div class="table-list" :class="{'table-list--loading': loading }">
		<div class="table-list__header" :class="{'table-list__header--sticky': isSticky}" v-if="hasHeader">
			<slot name="header"></slot>
		</div>
		<loading-line class="table-list__loading-line" />

		<div
			class="table-list__item"
			:class="{ 'table-list__item--no-hover': noHover }"
			:key="key"
			v-for="(item, key) in items"
		>
			<slot name="item" :item="item" :idx="key"></slot>
		</div>

		<div class="table-list__footer" v-if="hasFooter">
			<span class="table-list__count" v-if="listCount">{{ listCount | number(false, 0) }}</span>
		</div>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'TableList',
	components: {
		LoadingLine,
		LoadingSpinner,
	},
	props: {
		items: {
			type: [Array, Object],
			default: () => ([]),
		},
		loading: Boolean,
		noHover: Boolean,
		isSticky: Boolean,
	},
	computed: {
		hasHeader() {
			return Boolean(this.$slots.header)
		},
		hasFooter() {
			return Boolean(this.$slots.footer)
		},
		pageCount() {
			return this.$store.getters['misc/pageCount']
		},
		listCount() {
			return this.$store.getters['misc/listCount']
		},
	},
}
</script>

<style scoped lang="scss">
.table-list {
	position: relative;

	&__header {
		background-color: modules.color_('background');
		transition: background-color 150ms linear;

		// @include modules.media-query('phone') {
		// 	display: none;
		// }

		&--sticky {
			position: sticky;
			top: 0px;
			z-index: 7;
		}
	}

	&__item {
		@include modules.fontSize(14px);
		transition: background-color 250ms linear;

		&:hover {
			background-color: modules.color_('background', 'light');
		}
	}

	&__loader {
		align-items: center;
		background-color: rgba(255, 255, 255, 0.3);
		justify-content: center;
		display: none;
		height: 100%;
		position: absolute;
		top: 80px;
		width: 100%;
		z-index: -1;

		.table-list--loading & {
			display: flex;
			z-index: 2;
		}
	}

	&__loading-line {
		height: 2px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 150ms linear;

		.table-list--loading & {
			opacity: 1;
		}
	}
}
</style>

<template>
	<div
		class="mdc-dialog"
		role="alertdialog"
		aria-modal="true"
		aria-labelledby="dialog_title"
		aria-describedby="dialog_content"
	>
		<div class="mdc-dialog__container">
			<div class="mdc-dialog__surface">
				<div class="mdc-dialog__heading">
					<h2 class="mdc-dialog__title" id="dialog_title" v-if="title">{{title}}</h2>
					<button type="button" class="mdc-icon-button" aria-label="Close Dialog" aria-hidden="false" @click="close()">
						<ion-icon name="close"></ion-icon>
					</button>
				</div>
				<div class="mdc-dialog__content" id="dialog_content">
					<slot name="content" :listener="listener"></slot>
				</div>
				<footer class="mdc-dialog__actions" v-if="$slots.actions">
					<slot name="actions">
						<!-- <button type="button" class="mdc-button mdc-dialog__button" @click="close()">Close</button> -->
					</slot>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>
// Dependencies
import { MDCDialog } from '@material/dialog';

// Component
export default {
	name: 'modal',
	props: {
		title: String,
		content: String,
		open: Boolean,
	},
	data: () => ({
		dialog: null,
		listener: null,
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
		if (this.open) {
			this.dialog.open()
		}
		this.dialog.listen('MDCDialog:closed', () => this.$emit('close'))
		this.listener = this.dialog.listen
	},
	methods: {
		close() {
			this.dialog.close()
		},
	},
	watch: {
		open(val) {
			if (val) {
				this.dialog.open()
			} else {
				this.dialog.close();
			}
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/dialog/mdc-dialog';
// @import '@material/button/mdc-button';
@use "@material/icon-button/mdc-icon-button";

.mdc-dialog {
	z-index: 30;

	&__heading {
		align-items: center;
		border-bottom: 1px solid modules.color_('border');
		display: flex;
		position: relative;

		.mdc-icon-button {
			color: modules.color_('text', 'xlight');
			position: absolute;
			right: 0;
			transform: translateY(4px);

			&__icon {
				font-size: 20px;
			}

			&:hover {
				color: modules.color_('text');
			}
		}
	}

	.mdc-dialog__surface {
		@include modules.media-query(desktop) {
			max-width: 80vw;
		}
	}

	&__title {
		flex: 1;
		text-align: center;
	}

}
</style>

<template>
	<div class="list-organizations">
		<table-list
			:loading="loading"
			:items="organizations"
		>
			<template slot="header">
				<span
					:class="`organizations-header-item organizations-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`organizations-item organizations-item--${key}`"
					:key="`item_${key}`"
					:to="{ name: 'organization', params: { orgId: item.orgId }}"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
					@click.native="$event.stopImmediatePropagation()"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'
import { OrganizationStatus } from '@/lib/enums'

import TableList from '@/components/TableList'

export default {
	name: 'ListOrganizations',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		organizations: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			name: 'Name',
			'settings.certNumber': 'Cert Number',
			'address.city': 'City',
			'address.state': 'State',
			'settings.institutionalMarkup': `Inst. Markup`,
			dateCreated: 'Created',
			status: 'Status',
		},
	}),
	methods: {
		get,
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					const dt = parseISO(value)
					return `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true })}</span>`
				case 'status':
					return OrganizationStatus[value]
				case 'settings.institutionalMarkup':
					if ( !value ) return
					return `${value}%`
				default:
					return value
					break
			}

			return value
		},
	},
}
</script>

<style scoped lang="scss">
.list-organizations {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(7, 1fr);

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {
			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.organizations-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.organizations-item {
		color: modules.color_('text', 'dark');
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&:first-of-type {
			@include modules.media-query('phone') {
				font-weight: 600;
			}
		}

		&--pending {
			&.organizations-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.organizations-item--status {
				color: modules.color_('alert', 'critical')
			}
		}
	}
}
</style>

<template>
	<div class="partial partial--opportunies opportunities">
		<div class="partial__heading">
			<h2 class="partial__title">Opportunities</h2>
			<div class="partial__actions">
				<router-link :to="{name: 'opportunityNew'}" class="button button--secondary button--unelevated">
					<span class="mdc-button__label">Create New Opportunity</span>
					<span class="mdc-button__icon">
						<ion-icon name="add-circle-outline" />
					</span>
				</router-link>
			</div>
		</div>
		<card class="partial__body">
			<tab-bar :items="tabs">
				<template slot-scope="{ item, idx }" slot="item">
					<router-link
						class="tab-bar__item a"
						:class="{'tab-bar__item--active':idx === activeTab}"
						:id="idx"
						:to="item.link"
					>
						{{item.label}}
					</router-link>
				</template>
				<template slot="actions">
					<form class="partial__search" @submit.prevent.stop="submitForm(fields)">
						<div>
							<a @click="showOrg=true" v-if="isAdmin && !showOrg"><small>Search by Organization</small></a>
							<autocomplete-organization @selected="orgSelect" :compact="true" :search="true" v-if="isAdmin && showOrg" />
						</div>
						<div>
							<a @click="showOrg=false" v-if="isAdmin && showOrg"><small>Search by Keyword</small></a>
							<text-field class="form__field form__field--search" label="Search by Keyword" compact="true" v-model="fields.name" type="search" autocomplete="off" @input="checkSearch" v-if="!showOrg">
								<span slot="pre"><ion-icon name="search"></ion-icon></span>
							</text-field>
						</div>
					</form>
				</template>
			</tab-bar>

			<list-opportunities :opportunities="opportunities" :loading="isLoading" :orderBy="orderBy" :order="order" @sort="sort" />
			<pagination :limit="limit" :count="count" />
		</card>
	</div>
</template>

<script>
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Card from '@/components/Card'
import ListOpportunities from '@/components/ListOpportunities'
import Pagination from '@/components/Pagination'
import TabBar from '@/components/TabBar'
import TextField from '@/components/TextField'

export default {
	name: 'PartialOpportunities',
	components: {
		AutocompleteOrganization,
		Card,
		ListOpportunities,
		Pagination,
		TabBar,
		TextField,
	},
	data: () => ({
		isLoading: false,
		fromQuery: null,
		limit: 50,
		fields: {},
		showOrg: false,
		orderBy: 'dateCreated',
		order: 'desc',
	}),
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
		user() {
			return this.$store.getters['user/me']
		},
		opportunities() {
			let opportunities = this.$store.getters['opportunity/opportunities']
			opportunities = opportunities.map((o) => {
				o.spread = undefined
				o.maturity = undefined
				let option = o.pricingOptions?.find(p => p.active)
				if (option) {
					o.spread = option.spread
					o.maturity = option.maturity ? option.maturity + `yr` : undefined
					o.org = o.organization?.name
				}
				return o
			})

			return opportunities
		},
		count() {
			return this.opportunities.length
		},
		tabs() {
			const tabs = []
			tabs.push(
				{
					label: 'My Opportunities',
					link: {
						name: 'main',
						query: {
							type: undefined,
						}
					},
				}
			)
			tabs.push({
				label: `All Opportunities`,
				link: {
					name: `main`,
					query: {
						type: `all`,
					},
				},
			})

			return tabs
		},
		activeTab() {
			if (!this.$route.query.key) return this.tabs.findIndex((t) => t.link.query.type == this.$route.query.type)
		},
	},
	methods: {
		async submitForm(fields) {
			if(fields.name) {
				await this.$router.push({ query: { key: 'keywords.name', op: 'array-contains', value: fields.name } })
			} else {
				await this.$router.push({ query: {} })
			}
		},
		async checkSearch(val) {
			if (!val) {
				await this.$router.push({ query: {} })
			}
		},
		async orgSelect(val) {
			if (!val) {
				await this.$router.push({ query: {} })
			} else {
				await this.$router.push({ query: { key: 'orgId', op: '==', value: val.value }})
			}
		},
		sort(orderBy) {
			if (orderBy == this.$route.query?.orderBy) {
				this.order = (this.order == 'desc') ? 'asc' : 'desc'
			} else {
				this.orderBy = orderBy
			}

			const query = {
				...this.$route.query,
				...{
					orderBy: this.orderBy,
					order: this.order,
				}
			}

			this.$router.push({ query })
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				if (from && from.name == 'opportunities' && to.name == 'opportunity') return this.fromQuery = from.query
				if (from && from.name == 'opportunity'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return

				this.isLoading = true

				let _queryDefault = {
					key: this.isAdmin ? 'createdBy' : 'clientId',
					op: '==',
					value: this.user.uid,
				}
				if (this.$route.query && this.$route.query.type == 'all') {
					_queryDefault = {}
				}

				const query = {
					..._queryDefault,
					...to.query,
				}

				this.$store.dispatch('opportunity/list', { ...query, p: query.p || 1, limit: this.limit, orderBy: this.orderBy, order: this.order })
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.then(() => {

					})
					.then(() => {
						this.isLoading = false
					})

				if (to.query && to.query.key) {
					this.fields[to.query.key.replace(/keywords\./i, '')] = to.query.value
				}
			},
		},
	},
	metaInfo: () => ({
		title: 'Opportunities',
	}),
}
</script>

<style scoped lang="scss">
.partial__search {
	border-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@include modules.media-query('phone') {
		justify-content: center;
	}

	> div {
		flex: 0 1 50%;
		margin-left: 1rem;

		.mdc-text-field {
			width: 100%;
		}

		> a {
			//flex: 0 1 25%;
			white-space: nowrap;
			display: block;
			text-align: center;
		}
	}
}

.tab-bar {

	@include modules.media-query('phone') {
		flex-direction: column-reverse;

		::v-deep &__items {
			min-height: 3em;
		}
	}

	@include modules.media-query('tablet') {
		::v-deep &__actions {
			flex: 1.25;
		}
	}
}
</style>

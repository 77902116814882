<template>
	<form class="form form--rates form-rates" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<h2 class="form-section__title">Rates Curve</h2>
			<p class="form-section__description">Last Updated: {{ rate.dateModified | date('MMM dd, yyyy hh:mma') }}</p>
		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<file-field class="form__field" @change="handleFile" label="Select File (.csv)" :preview="true" />
				<button type="submit" class="button button--primary button--unelevated">Update</button>
			</div>
		</div>

		<div class="form-section">
			<table-list
				:items="fields"
			>
				<template slot="header">
					<span class="rates-header-item">
						Interval
					</span>
					<span class="rates-header-item">
						Reset Rate
					</span>
					<span class="rates-header-item">
						Discount
					</span>
				</template>

				<template slot="item" slot-scope="{ item }">
					<span class="rates-item">
						{{ item.resetDate }}
					</span>
					<span class="rates-item">
						<text-field label="Rate (%)" type="tel" v-model="item.resetRate" :compact="true" v-mask="'#.#####'" />
					</span>
					<span class="rates-item">
						<text-field label="Discount (%)" tel="tel" v-model="item.discount" :compact="true" v-mask="'#.#####'" />
					</span>
				</template>
			</table-list>
		</div>

	</form>
</template>

<script>
import { camelCase, pick } from 'lodash'
import { mask } from 'vue-the-mask'
import { parse } from 'papaparse'
import { parse as dateParse, parseISO, format, isToday } from 'date-fns'
import FileField from '@/components/FileField'
import LoadingSpinner from '@/components/LoadingSpinner'
import TableList from '@/components/TableList'
import TextField from '@/components/TextField'
import UploadField from '@/components/UploadField'

export default {
	name: 'FormRatesCurve',
	components: {
		FileField,
		LoadingSpinner,
		TableList,
		TextField,
		UploadField,
	},
	directives: {
		mask,
	},
	data: () => ({
		isLoading: false,
		rates: [],
		activeSpot: 0,
		fields: [],
	}),
	computed: {
		rate() {
			return this.$store.getters['rate/rate']
		},
	},
	mounted() {
		this.isLoading = true
		this.$store.dispatch('rate/get', `curve`)
			.then((res) => {
				if (res.data) {
					this.fields = res.data
					this.rates = res.data
				}
			})
			.catch((error) => {
				this.$notice(`ERROR: ${error.message || error}`)
			})
			.finally(() => {
				this.isLoading = false
			})
	},
	destroyed() {
		this.$store.dispatch('rate/unset')
	},
	methods: {
		handleFile(files) {
			const reader = new FileReader()
			if (files[0]) {
				const file = files[0]
				reader.readAsText(file)
			}

			reader.onload = (file) => {
				this.isLoading = true
				try {
					let info = parse(file.target.result, {
						header: true,
						transformHeader: (val, idx) => camelCase(val)
					})

					const filtered = info.data.filter(r => r.resetDate && r.resetRate && r.discount).map(r => pick(r, ['resetDate', 'resetRate', 'discount']))
					if (filtered.length) {
						this.fields = filtered.map(r => {
							try {
								r.resetDate = format(dateParse(r.resetDate, 'MM/d/yyyy', new Date()), 'MM/dd/yyyy')
							} catch (error) {
								r.resetDate = format(dateParse(r.resetDate, 'M/d/yy', new Date()), 'MM/dd/yyyy')
							}
							r.resetRate = Number(r.resetRate)
							r.discount = Number(r.discount)

							return r
						})
					} else {
						this.$notice(`ERROR: File does not appear to be correct data`)
					}
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
				this.isLoading = false
			}
		},
		removeRow(idx) {
			this.fields.splice(idx, 1)
		},
		submitForm(fields) {
			this.isLoading = true
			this.$store.dispatch('rate/update', { rateType: 'curve', rateData: fields })
				.then((res) => {
					this.rates = res.data
					this.$notice(`Rates updated`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.form-rates {

	::v-deep .table-list {
		&__item,
		&__header {
			align-items: center;
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(2, minmax(160px,1fr)) 160px;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}

			.hover {
				visibility: hidden;
			}

			&:hover {
				.hover {
					visibility: visible;
				}
			}
		}
	}

	.rates-header-item {
		@include modules.gutter('padding');
	}

	.rates-item {
		padding: 0.25rem;
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');

		&:last-of-type {
			@include modules.gutter('padding-left', 0.25);
			@include modules.gutter('padding-right', 0.25);
		}
	}
}
</style>

<template>
	<div class="modal modal--help modal-help">
		<div class="modal__loading" v-if="loading">
			<loading-spinner />
		</div>
		<div class="modal-help__tabs">
			<tab-bar
				:items="tabItems">
				<template slot-scope="{ item, idx }" slot="item">
					<div
						class="tab-bar__item a"
						:class="{'tab-bar__item--active': activeTab == idx}"
						:id="`type_tab_${idx}`"
						@click="activeTab = idx"
						>
						{{ item.label }}
					</div>
				</template>
			</tab-bar>
		</div>
		<div class="modal-help__body">
			<template v-if="activeTab == 0">
				<iframe src="https://player.vimeo.com/video/712427572?&amp;autoplay=0&amp;loop=1&amp;muted=0" width="800" height="450" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" id="video_frame"></iframe>
			</template>
			<template v-if="activeTab == 1">
				<iframe :src="`https://csbank.formstack.com/forms/arc_support_request?${formParams}`" width="800" height="820" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" id="form_frame"></iframe>
			</template>
		</div>
	</div>
</template>

<script>
import TabBar from '@/components/TabBar'

export default {
	name: 'ModalHelp',
	components: {
		TabBar,
	},
	data: () => ({
		loading: false,
		tabItems: [
			{
				label: 'How-to Video',
				link: {},
			},
			{
				label: 'Request Support',
				link: {},
			},
		],
		activeTab: 0,
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		formParams() {
			let string = ``
			const _params = {
				'field125193236': this.me?.firstName,
				'field125193240': this.me?.lastName,
				'field125193325': this.me?.email,
				'field125193242': this.me?.organization?.name,
				'field125193248': this.me?.organization?.address?.state,
			}

			for (const key in _params) {
				string += `${key}=${_params[key]}&`
			}
			return string
		},
	},
}
</script>

<style scoped lang="scss">
.modal-help {
	&__body {
		@include modules.gutter('padding-top');
	}
}
</style>

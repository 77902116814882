<template>
	<div class="partial partial--rates rates">
		<div class="partial__heading">
			<h2 class="partial__title">
				Daily Rates<br />
				<span v-if="dateModified">Updated: {{ dateModified | date }}</span>
			</h2>
			<div class="partial__actions">
				<a :href="`${portalUrl}profile`" class="button button--unelevated button--secondary button--medium">Manage Daily ARC Email Subscription</a>
			</div>
		</div>
		<div class="partial__body">
			<p class="partial__description">Here is the current loan hedge pricing for a sample ARC transaction that is at least $1 million (ARC is available for loans as small as $250k). Please note that the below table is the applicable fixed rate without a credit spread. To arrive at a fixed rate to a borrower add the bank’s desired credit spread to the hedge rates shown below. The bank will earn a yield equal to daily SOFR paid monthly (which is currently {{ (sofr / 100) | percentage }}) plus the same credit spread.</p>
		</div>
		<div class="partial__body">
			<div class="form-row form-row--col-4" :class="{'form-row--col-6':!isAdmin}">
				<select-field v-model="dateStart" label="Start Date" :options="startDates" />
				<autocomplete-organization  @selected="orgSelect" :value="orgId" :label="organization | organization" v-if="isAdmin" />
			</div>
		</div>
		<card class="partial__body">
			<list-rates :rates="table" :loading="isLoading" />
		</card>
		<div class="partial__body">
			<p class="partial__description">These rates are indicative, based on assumption of minimum loan size of $1 million and subject to change based on market conditions. To price a live transaction, please contact the desk at <a href="tel:+18004812443">1-800-481-2443</a> or <a href="mailto:arc@southstatebank.com">arc@southstatebank.com</a></p>
		</div>
	</div>
</template>

<script>
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Card from '@/components/Card'
import ListRates from '@/components/ListRates'
import SelectField from '@/components/SelectField'

export default {
	name: 'PartialRates',
	components: {
		AutocompleteOrganization,
		Card,
		ListRates,
		SelectField,
	},
	data: () => ({
		isLoading: false,
		fromQuery: null,
		table: null,
		dateModified: null,
		portalUrl: process.env.PORTAL_URL || process.env.VUE_APP_PORTAL_URL,
		libor: null,
		sofr: null,
		dateStart: 0,
		orgId: null,
		organization: '',
	}),
	mounted() {
	},
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
		rates() {
			return this.$store.getters['opportunity/opportunities']
		},
		count() {
			return this.rates.length
		},
		startDates() {
			return [
				{
					value: 0,
					label: `Spot Start`,
				},
				{
					value: 1,
					label: `1mo Forward`,
				},
				{
					value: 3,
					label: `3mo Forward`,
				},
				{
					value: 6,
					label: `6mo Forward`,
				},
				{
					value: 9,
					label: `9mo Forward`,
				},
				{
					value: 12,
					label: `12mo Forward`,
				},
				{
					value: 18,
					label: `18mo Forward`,
				},
				{
					value: 24,
					label: `24mo Forward`,
				},
			]
		},
	},
	methods: {
		orgSelect(selected) {
			this.orgId = selected.value
			this.organization = selected.label
		},
	},
	watch: {
		dateStart: {
			immediate: true,
			handler: function (dateStart) {
				this.isLoading = true
				this.$store.dispatch('rate/daily', { dateStart, orgId: this.orgId, })
					.then((res) => {
						const { data, libor, sofr, dateModified } = res
						this.dateModified = dateModified
						this.table = Object.values(data)
						this.libor = libor
						this.sofr = sofr
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			},
		},
		orgId: {
			immediate: true,
			handler: function (orgId) {
				console.log('watch.orgId', orgId)
				// if (!orgId) return
				this.isLoading = true
				this.$store.dispatch('rate/daily', { dateStart: this.dateStart, orgId, })
					.then((res) => {
						const { data, libor, sofr, dateModified } = res
						this.dateModified = dateModified
						this.table = Object.values(data)
						this.libor = libor
						this.sofr = sofr
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			},
		},
	},
	metaInfo: () => ({
		title: 'Daily Rates',
	}),
}
</script>

<style scoped lang="scss">
.rates {
	.partial {
		&__heading {
			@include modules.gutter('margin-bottom');
		}

		&__title {
			span {
				@include modules.gutter('margin-left', .5);
				@include modules.fontSize(12px);
				color: modules.color_('text', 'medium');
			}
		}
	}

	.list-rates {
		@include modules.gutter('margin-top', 1.25);
		@include modules.gutter('margin-bottom');
	}
}
</style>

<template>
	<div class="partial partial--settings settings">
		<div class="partial__heading">
			<h2 class="partial__title">Settings</h2>
		</div>
		<div class="partial__tabs">
			<tab-bar
				class="partial__tab-bar"
				:items="tabs"
				>
				<template slot-scope="{ item, idx }" slot="item">
					<div
						class="tab-bar__item a"
						:class="{'tab-bar__item--active': item.link == activeTab.link}"
						:id="`opportunity_tab_${idx}`"
						@click="setTab(item)"
						>
						{{ item.label }}
					</div>
				</template>
			</tab-bar>
		</div>

		<card class="partial__body">
			<template v-if="tab == undefined">
				<form-rates-matrix />
			</template>
			<template v-if="tab == 'curve'">
				<form-rates-curve />
			</template>
			<template v-if="tab == 'markup'">
				<form-rates-markup />
			</template>
			<template v-if="tab == 'minimum'">
				<form-rates-minimum />
			</template>
			<template v-if="tab == 'varCube'">
				<form-rates-var-cube />
			</template>
			<template v-if="tab == 'varValue'">
				<form-rates-var-value />
			</template>
		</card>
	</div>
</template>

<script>
import Card from '@/components/Card'
import FormRatesCurve from '@/components/FormRatesCurve'
import FormRatesMarkup from '@/components/FormRatesMarkup'
import FormRatesMatrix from '@/components/FormRatesMatrix'
import FormRatesMinimum from '@/components/FormRatesMinimum'
import FormRatesVarCube from '@/components/FormRatesVarCube'
import FormRatesVarValue from '@/components/FormRatesVarValue'
import TabBar from '@/components/TabBar'

export default {
	name: 'PartialSettings',
	components: {
		Card,
		FormRatesCurve,
		FormRatesMarkup,
		FormRatesMatrix,
		FormRatesMinimum,
		FormRatesVarCube,
		FormRatesVarValue,
		TabBar,
	},
	data: () => ({
		activeTab: null,
		isLoading: false,
		tabs: [
			// {
			// 	label: 'Defaults',
			// 	link: { tab: undefined },
			// },
			{
				label: 'Rates Matrix',
				link: { tab: undefined },
			},
			{
				label: 'Rates Curve',
				link: { tab: 'curve' },
			},
			{
				label: 'Structured Markup',
				link: { tab: 'markup' },
			},
			{
				label: 'Minimum Markup',
				link: { tab: 'minimum' },
			},
			{
				label: 'VaR V Cube',
				link: { tab: 'varCube' },
			},
			{
				label: 'VaR Values',
				link: { tab: 'varValue' },
			},
		],
	}),
	computed: {
		tab() {
			return this.$route.params.tab
		},
	},
	methods: {
		setTab(tab) {
			// this.activeTab = tab
			this.$router.push({ name: this.$route.name, params: { tab: tab.link.tab }})
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(newVal, oldVal) {
				const activeTab = this.tabs.find(t => t.link.tab == newVal.params.tab)
				if (activeTab) {
					this.activeTab = activeTab

					// if (activeTab.link.tab == 'presentations') {
					// 	const { opportunityId } = this.$route.params
					// 	this.$store.dispatch('opportunity/listPresentations', { opportunityId })
					// }
				}
			},
		},
	},
	metaInfo: () => ({
		title: `Settings`,
	}),
}
</script>

<style scoped lang="scss">

</style>

<template>
	<div class="list-users">
		<table-list
			:loading="loading"
			:items="users"
		>
			<template slot="header">
				<span
					:class="`users-header-item users-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`users-item users-item--${key}`"
					:key="`item_${key}`"
					:to="{ name: 'user', params: { uid: item.uid }}"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
					@click.native="$event.stopImmediatePropagation()"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'
import { UserStatus } from '@/lib/enums'

import TableList from '@/components/TableList'

export default {
	name: 'ListUsers',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		users: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			// name: 'Name',
			nameEmail: 'Name',
			// profileName: 'Name',
			// email: 'Email',
			organization: 'Organization',
			// permissions: 'Applications',
			dateCreated: 'Created',
			dateLogin: 'Last Login',
			status: 'Status',
		},
	}),
	methods: {
		get,
		transform(key, value) {
			switch(key) {
				case 'organization':
					if (!value) return
					let toReturn = value.name
					if (value.address) {
						toReturn += '<br />'+ [value.address.city, value.address.state].join(', ')
					}
					return toReturn
					break
				case 'dateCreated':
					if (!value) return ``
					return `${format(parseISO(value), 'PPp')}`
				case 'dateLogin':
					if (!value) return '(no login yet)'
					try {
						const dt = parseISO(value)
						return `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true})}</span>`
					} catch (error) {
						return ``
					}
				case 'status':
					return UserStatus[value]
				default:
					return value
					break
			}

			return value
		},
	},
}
</script>

<style scoped lang="scss">
.list-users {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(5, 1fr);

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {
			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.users-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.users-item {
		color: modules.color_('text', 'dark');
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&:first-of-type {
			@include modules.media-query('phone') {
				font-weight: 600;
			}
		}

		&--pending {
			&.users-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.users-item--status {
				color: modules.color_('alert', 'critical')
			}
		}
	}

}
</style>

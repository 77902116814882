<template>
	<div class="partial partial--activity activity">
		<div class="partial__heading">
			<h2 class="partial__title">Activity</h2>
		</div>
		<card class="partial__body">
			<list-activity :events="events" :loading="isLoading" />
		</card>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListActivity from '@/components/ListActivity'

export default {
	name: 'PartialActivity',
	components: {
		Card,
		ListActivity,
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		events() {
			return this.$store.getters['opportunity/events']
		},
	},
	async mounted() {
		this.isLoading = true
		try {
			await this.$store.dispatch(`opportunity/listAllActivity`, {})
		} catch (error) {
			this.$notice(`ERROR: ${error.message || error}`)
		}
		this.isLoading = false
	},
}
</script>

<style scoped lang="scss">
.activity {

}
</style>

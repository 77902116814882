<template>
	<div class="partial partial--tools tools">
		<div class="partial__heading">
			<h2 class="partial__title">Tools</h2>
		</div>

		<div class="partial__tabs">
			<tab-bar
				class="partial__tab-bar"
				:items="tabs"
				>
				<template slot-scope="{ item, idx }" slot="item">
					<div
						class="tab-bar__item a"
						:class="{'tab-bar__item--active': item.link == activeTab.link}"
						:id="`opportunity_tab_${idx}`"
						@click="setTab(item)"
						>
						{{ item.label }}
					</div>
				</template>
			</tab-bar>
		</div>

		<card class="partial__body">
			<template v-if="tab == undefined">
				<form-tools-market />
			</template>
			<template v-if="tab == 'salesforce'">
				<form-tools-salesforce />
			</template>
			<template v-if="tab == 'bval'">
				<form-tools-bval />
			</template>
		</card>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="partial__modal" slot="content" name="pageModal" @close="hideModal(true)" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import FormToolsBval from '@/components/FormToolsBval'
import FormToolsMarket from '@/components/FormToolsMarket'
import FormToolsSalesforce from '@/components/FormToolsSalesforce'
import Modal from '@/components/Modal'
import TabBar from '@/components/TabBar'

export default {
	name: 'PartialTools',
	components:{
		Card,
		FormToolsBval,
		FormToolsMarket,
		FormToolsSalesforce,
		Modal,
		TabBar,
	},
	data: () => ({
		activeTab: null,
		isLoading: false,
		tabs: [
			// {
			// 	label: 'Defaults',
			// 	link: { tab: undefined },
			// },
			{
				label: 'Mark to Market',
				link: { tab: undefined },
			},
			// {
			// 	label: 'BVAL',
			// 	link: { tab: 'bval' },
			// },
			{
				label: 'Salesforce Sync',
				link: { tab: 'salesforce'},
			},
		],
	}),
	computed: {
		tab() {
			return this.$route.params.tab
		},
		modalVisible() {
			return this.$route.name == 'tradeEdit'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		setTab(tab) {
			// this.activeTab = tab
			this.$router.push({ name: this.$route.name, params: { tab: tab.link.tab }})
		},
		hideModal(clicked) {
			if (clicked) {
				this.$router.replace({ name: 'tools', params: { tab: this.$route.params.tab }})
			} else {
				this.$router.back()
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(newVal, oldVal) {
				const activeTab = this.tabs.find(t => t.link.tab == newVal.params.tab)
				if (activeTab) {
					this.activeTab = activeTab

					// if (activeTab.link.tab == 'presentations') {
					// 	const { opportunityId } = this.$route.params
					// 	this.$store.dispatch('opportunity/listPresentations', { opportunityId })
					// }
				}
			},
		},
	},
	metaInfo: () => ({
		title: `Tools`,
	}),
}
</script>

<style scoped lang="scss">

</style>

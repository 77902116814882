<template>
	<div class="file-field">
		<label class="file-field__label button button--unelevated button--small button--secondary" :for="uid" :class="{'button--disabled': notEnabled}">{{ label }}</label>
		<input type="file" :id="uid" :accept="accept" :multiple="multiple" v-on="listeners" v-bind="attrs" :disabled="notEnabled" ref="file" />

		<ul class="file-field__list" v-if="files.length && preview">
			<li class="file-field__list-item" v-for="(file, key) in files" :key="key">
				<span class="file__name">
					<small>{{ file.name }}
					({{ file.size | bytes }})</small>
				</span>
				<span class="file__action" @click="removeFile(key)"><ion-icon name="trash"></ion-icon></span>
			</li>
		</ul>
	</div>
</template>

<script>
import { v4 } from 'uuid'

export default {
	name: 'FileField',
	props: {
		accept: {
			type: String,
			default: 'text/csv',
		},
		id: String,
		label: String,
		multiple: {
			type: Boolean,
			default: false,
		},
		preview: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({
		files: [],
	}),
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
				change: event => this.change(event),
			}
		},
		uid() {
			if (!this.id) {
				return v4()
			}
		},
		notEnabled() {
			return (this.files.length > 0 && !this.multiple) || this.$attrs.disabled
		},
	},
	methods: {
		input(event) {
			this.files = [...event.target.files || []]
			this.$emit('change', this.files)
		},
		change(event) {
			this.files = [...event.target.files || []]
			this.$emit('change', this.files)
		},
		removeFile(idx) {
			try {
				this.files.splice(idx, 1)
				this.$refs.file.value = ''
			} catch (error) {

			}
			this.$emit('change', this.files)
		},
	},
}
</script>

<style scoped lang="scss">
.file-field {
	input {
		background-color: rgba(0, 0, 0, 0);
		height: 1px;
		opacity: 0;
		overflow: hidden;
		position: fixed;
		width: 1px;
		z-index: -1;
	}

	.button--disabled {
		background-color: grey;
		color: white;
		cursor: not-allowed;

		&:hover {
			background-color: grey;
		}
	}

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;

		&-item {
			display: flex;
			flex-direction: row;
		}
	}

	.file {

		&__name {
			flex: 1;
		}

		&__action {
			cursor: pointer;
		}
	}
}
</style>

<template>
	<div class="main-nav">
		<div class="main-nav__desktop">
			<router-link
				class="main-nav__item"
				active-class="main-nav__item--active"
				v-for="(item, key) in menuItems"
				:key="key"
				:to="item.link"
				v-html="item.title"
			>
			</router-link>
			<a class="main-nav__item" @click="helpModalToggle(true)">
				<ion-icon name="help-circle-outline" />
			</a>
		</div>

		<div class="main-nav__mobile" :class="{'main-nav__mobile--open': menuOpen}">
			<ion-icon name="menu" class="main-nav__mobile-button" size="large" @click="menuToggle(null)"></ion-icon>

			<div class="main-nav__mobile-items">
				<router-link
					class="main-nav__mobile-item"
					active-class="main-nav__mobile-item--active"
					v-for="(item, key) in menuItems"
					:key="key"
					:to="item.link"
					v-html="item.title"
					@click.native="menuToggle(false)"
				>
				</router-link>
			</div>
		</div>

		<modal @close="hideHelpModal" :open="helpModalOpen" :title="helpModalTitle">
			<modal-help slot="content" />
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal'
import ModalHelp from '@/components/ModalHelp'

export default {
	name: 'MainNav',
	components: {
		Modal,
		ModalHelp,
	},
	data: () => ({
	}),
	computed: {
		hasBval() {
			return this.$store.getters['user/hasBval'] || this.$store.getters['user/hasBvalSsb']
		},
		isAdmin() {
			return this.$store.getters['user/isAdmin']
		},
		menuItems() {
			const menuItems =  [
				...this.$store.getters['navigation/menu']('header'),
				...this.$store.getters['navigation/menu']('admin'),
				...this.hasBval ? this.$store.getters['navigation/menu']('bval') : [],
			]

			return menuItems
		},
		menuOpen() {
			return this.$store.getters['navigation/menuOpen']
		},
		helpModalOpen() {
			return this.$store.getters['navigation/helpModalOpen']
		},
		helpModalTitle() {
			return `Videos and Support`
		},
	},
	methods: {
		menuToggle(status) {
			return this.$store.dispatch('navigation/menuToggle', status)
		},
		helpModalToggle(status) {
			return this.$store.dispatch('navigation/helpModalToggle', status)
		},
		hideHelpModal() {
			const frame = document.querySelector('iframe')
			if (frame) {
				const src = frame.src
				frame.src = src
			}

			return this.$store.dispatch('navigation/helpModalToggle', false)
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.main-nav {

	@include modules.fontSize(18px);
	padding: 14px 0;

	@include modules.media-query('desktop') {
		padding: 0;
	}

	&__desktop {
		display: none;

		@include modules.media-query('desktop') {
			display: block;
		}
	}

	&__item {
		color: modules.color_('text');
		display: inline-block;
		height: 4rem;
		line-height: 4rem;
		margin: 0 0.5em;
		padding: 0 0.5em;
		position: relative;
		text-decoration: none;
		transition: color 250ms modules.$ease-out;

		@media screen and (min-width: 840px) and (max-width: 940px) {
			@include modules.fontSize(17px);
			margin: 0 0.25em;
		}

		&:hover {
			color: modules.color_('primary', 'light');
		}

		&:after {
			background-color: modules.color_('primary', 'light');
			bottom: 0;
			content: ' ';
			display: block;
			height: 4px;
			left: 0;
			position: absolute;
			transition: transform 250ms modules.$ease-out;
			transform-origin: center center;
			transform: scaleX(0);
			width: 100%;
		}

		&--active {
			color: modules.color_('primary');

			&:after {
				transform: scaleX(1);
			}
		}

		ion-icon {
			transform: translateY(3px);
		}
	}

	&__mobile {
		$mobile: &;

		position: relative;
		//top: 5px;

		@include modules.media-query('desktop') {
			display: none;
		}

		&-button {
			display: block;
			color: modules.color_('text');
		}

		&-items {
			padding: 1.5em;
			min-width: 240px;
			@include elevation.elevation(10);
			background-color: modules.color_('primary', 'dark');
			opacity: 0;
			position: absolute;
			pointer-events: none;
			right: 0;
			transform: translateY(-20%);
			transition: all 150ms modules.$ease-out;
			z-index: 9;
			display: flex;
			flex-direction: column;

			#{$mobile}--open & {
				opacity: 1;
				pointer-events: all;
				transform: translateY(0);
			}
		}

		&-item {
			color: modules.color_('text', 'medium');
			@include modules.fontSize(16px);
			font-weight: 600;
			line-height: 1.375;
			margin-bottom: .75em;
			margin-top: .75em;
			text-decoration: none;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			&--active {
				color: modules.color_('primary', 'text');

				span {
					&:hover {
						border-bottom-color: modules.color_('text', 'accent');
						color: modules.color_('primary', 'text');
					}
				}

			}


		}
	}
}
</style>

<template>
	<div class="list-rates">
		<table-list
			:loading="loading"
			:items="rates"
		>
			<template slot="header">
				<span
					:class="`rates-header-item rates-header-item--am`"
				>
					Am.
				</span>
				<span
					:class="`rates-header-item rates-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<span
					:class="`rates-item rates-item-am`"
				>
					<strong>{{ item['am'] }}yr Am.</strong>
				</span>
				<span
					:class="`rates-item rates-item--${key}`"
					:key="`item_${key}`"
					v-for="(col, key) in columns"
				>
					<template v-if="item[key]">
						<span class="mobile-label">{{ key }} -</span>
						{{ item[key] | percentage }}
					</template>
					<template v-else>
						-
					</template>
				</span>
			</template>
		</table-list>
	</div>
</template>

<script>
import { get } from 'lodash'

import TableList from '@/components/TableList'

export default {
	name: 'ListRates',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		rates: {
			type: Array,
			default: () => ([]),
		},
	},
	computed: {
		isAdmin() {
			return this.$store.getters['user/isAdmin']
		},
		columns() {
			let columns = {
				// 'am': 'Am.',
				// 0: 'Am.',
				'3': '3yr',
				'4': '4yr',
				'5': '5yr',
				'6': '6yr',
				'7': '7yr',
				'8': '8yr',
				'9': '9yr',
				'10': '10y',
				'12': '12y',
				'15': '15y',
				'20': '20y'
			}

			return columns
		},
		rows() {
			let rows = {
				'Amortization': '3yr',
				'3yr': '0.57%',
				'4yr': '0.57%',
				'5yr': '0.57%',
				'6yr': '0.57%',
				'7yr': '0.57%',
				'8yr': '0.57%',
				'9yr': '0.57%',
				'10yr': '0.57%',
				// '12yr': '0.57%',
				'15yr': '0.57%',
				'20yr': '0.57%'
			}

			return rows
		},
	}
}
</script>

<style scoped lang="scss">
.list-rates {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(12, minmax(0, 1fr));

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {

			&:hover {
				background-color: modules.color_('background');
			}

			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.rates-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('tablet') {
			padding: 1.5em .5em;
		}

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.rates-item {
		color: modules.color_('text', 'dark');
		overflow: hidden;
		padding: 1.5em;
		text-decoration: none;
		white-space: nowrap;

		@include modules.media-query('tablet') {
			padding: 1.5em .5em;
		}

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&:first-of-type {
			@include modules.media-query('phone') {
				font-weight: 600;
			}
		}

		&--pending {
			&.organizations-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.organizations-item--status {
				color: modules.color_('alert', 'critical')
			}
		}

		.mobile-label {
			display: none;

			@include modules.media-query('phone') {
				display: inline-block;
			}
		}
	}

}
</style>

<template>
	<form class="form form--rates form-rates" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<div>
					<h2 class="form-section__title">Rates Matrix</h2>
					<p class="form-section__description">Last Updated: {{ rate.dateModified | date('MMM dd, yyyy hh:mma') }}</p>
				</div>
				<div>
					<button type="button" class="button button--primary button--medium button--unelevated" :disabled="isLoading" :title="updatedToday ? `Send Daily ARC Email` : `Update today's rates to send`" @click="sendDailyEmail">Send Daily Email</button>
					<br />
					<small v-if="!updatedToday">(update today's rates to send)</small>
					<small>Last Sent: {{ rate.dateSent | date('MMM dd, yyyy hh:mma')}}</small>
				</div>
			</div>

		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<file-field class="form__field" @change="handleFile" label="Select File (.csv)" :preview="true" />
				<button type="submit" class="button button--primary button--unelevated" :disabled="isLoading">Update</button>
			</div>
		</div>

		<div class="form-section" v-if="results.updated.length || results.errors.length">
			<div class="form-rates__results">
				<div class="form-rates__results-list">
					<li class="list__item list__item--title">Updated ({{ results.updated.length || 0 }})</li>
					<li class="list__item"
						v-for="(item, key) in results.updated"
						:key="`updated_${key}`">
						<router-link :to="{name: 'organization', params: {orgId: item.orgId}}" target="_blank">
							{{ item.name }}
						</router-link><br />
						(<a :href="item.salesforceLink" target="salesforce">{{ item.settings.salesforceId }}</a>)
					</li>
				</div>
				<div class="form-rates__results-list">
					<li class="list__item list__item--title">Errors ({{ results.errors.length || 0 }})</li>
					<li class="list__item"
						v-for="(item, key) in results.errors"
						:key="`errors_${key}`">
						{{ item.name }}<br />
						{{ item.error }}
					</li>
				</div>
			</div>
		</div>

		<div class="form-section">

			<div class="spot-nav">
				<span class="spot-nav__title">Spot:</span> &nbsp;  <span class="spot-nav__item" :class="{'spot-nav__item--active': activeSpot == spot}" v-for="(spot, key) in spots" :key="key" @click="activeSpot = spot">
				{{ spot }}
				</span>
				<text-field class="libor-field" label="SOFR" v-mask="'#.####'" v-model="rates[0].sofr" />
			</div>

			<table-list
				:items="ratesBySpot"
			>
				<template slot="header">
					<span class="rates-header-item">
						Spot
					</span>
					<span class="rates-header-item">
						Amortization
					</span>
					<span class="rates-header-item">
						Term
					</span>
					<span class="rates-header-item">
						DV01
					</span>
					<span class="rates-header-item">
						Rate
					</span>
				</template>

				<template slot="item" slot-scope="{ item }">
					<span class="rates-item">
						{{ item.spot }}
					</span>
					<span class="rates-item">
						{{ item.amortization }}
					</span>
					<span class="rates-item">
						{{ item.term }}
					</span>
					<span class="rates-item">
						<text-field label="DV01 (%)" v-model="item.dv01" :compact="true" />
					</span>
					<span class="rates-item">
						<text-field label="Rate (%)" v-model="item.rate" :compact="true" v-mask="'#.###'" />
					</span>
				</template>
			</table-list>

			<div class="spot-nav">
				<span class="spot-nav__title">Spot:</span> &nbsp;  <span class="spot-nav__item" :class="{'spot-nav__item--active': activeSpot == spot}" v-for="(spot, key) in spots" :key="key" @click="activeSpot = spot">
				{{ spot }}
				</span>
			</div>

		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<span></span>
				<button type="submit" class="button button--primary button--unelevated" :disabled="isLoading">Update</button>
			</div>
		</div>


	</form>
</template>

<script>
import { camelCase, pick } from 'lodash'
import { mask } from 'vue-the-mask'
import { parse } from 'papaparse'
import { parse as dateParse, parseISO, format, isToday, addMinutes } from 'date-fns'
import FileField from '@/components/FileField'
import LoadingSpinner from '@/components/LoadingSpinner'
import TableList from '@/components/TableList'
import TextField from '@/components/TextField'
import UploadField from '@/components/UploadField'

const SALESFORCE_URL = process.env.SALESFORCE_URL || process.env.VUE_APP_SALESFORCE_URL

export default {
	name: 'FormRatesMatrix',
	components: {
		FileField,
		LoadingSpinner,
		TableList,
		TextField,
		UploadField,
	},
	directives: {
		mask,
	},
	data: () => ({
		isLoading: false,
		rates: [{}],
		activeSpot: 0,
		fields: [],
		results: {
			updated: [],
			errors: [],
		},
	}),
	computed: {
		spots() {
			let spots = []
			if (!this.rates.length) return spots

			this.rates.forEach(rate => {
				if (spots.indexOf(rate.spot) < 0 && rate.spot) {
					spots.push(rate.spot)
				}
			})

			return spots
		},
		ratesBySpot() {
			return this.fields.filter(r => r.spot == this.activeSpot)
		},
		rate() {
			return this.$store.getters['rate/rate']
		},
		updatedToday() {
			if (!this.rate || !this.rate.dateModified) return false
			return isToday(parseISO(this.rate.dateModified))
		},

	},
	mounted() {
		this.isLoading = true
		this.$store.dispatch(`rate/get`, `matrix`)
			.then((res) => {
				if (res.data) {
					this.fields = res.data
					this.rates = res.data
				}
			})
			.catch((error) => {
				this.$notice(`ERROR: ${error.message || error}`)
			})
			.finally(() => {
				this.isLoading = false
			})
	},
	destroyed() {
		this.$store.dispatch('rate/unset')
	},
	methods: {
		handleFile(files) {
			const reader = new FileReader()
			if (files[0]) {
				const file = files[0]
				reader.readAsText(file)
			}

			reader.onload = (file) => {
				this.isLoading = true
				try {
					let info = parse(file.target.result, {
						header: true,
						transformHeader: (val, idx) => camelCase(val),
					})

					const filtered = info.data.filter(r => r.spot && r.amortization && r.term).map(r => pick(r, ['spot', 'amortization', 'term', 'rate', 'dv01', 'libor', 'sofr',]))
					if (filtered.length) {
						this.fields = filtered
					} else {
						this.$notice(`ERROR: File does not appear to be correct data`)
					}

					this.rates = this.fields
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					// console.error(error)
				}
				this.isLoading = false
			}
		},
		submitForm(fields) {
			this.isLoading = true
			this.$store.dispatch('rate/update', { rateType: 'matrix', rateData: fields })
				.then((res) => {
					this.rates = res.data
					// this.$notice(`Rates updated`)

					this.isLoading = true
					this.$store.dispatch('tools/syncArcRates')
						.then((res) => {
							res.updated = res.updated.map(r => {
								r.salesforceLink = `${SALESFORCE_URL}/lightning/r/Account/${r.settings?.salesforceId}/view`
								return r
							})
							this.results.updated = res.updated
							this.results.errors = res.errors
						})
						.catch((error) => {
							this.$notice(`ERROR: ${error.message || error}`)
						})
						.finally(() => {
							this.isLoading = false
						})
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		removeRow(idx) {
			console.log('removeRow', idx)
		},
		sendDailyEmail() {

			this.$confirm(
				`This will send today's daily ARC Email...`,
				() => {
					const dt = format(addMinutes((new Date()), 45), 'hh:mma')
					this.isLoading = true
					this.$store.dispatch('rate/sendDailyEmail')
						.then((res) => {
							this.$notice(`Daily ARC Rates Email queued to send at ${dt}`)
						})
						.catch((error) => {
							this.$notice(`ERROR: ${error.message || error}`)
						})
						.finally(() => {
							this.isLoading = false
						})
				},
				{
					acceptLabel: `Yes, Send`,
				},
			)

		},
	},
}
</script>

<style scoped lang="scss">
.form-rates {

	::v-deep .table-list {
		&__item,
		&__header {
			align-items: center;
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(4, 1fr) 160px;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}

			.hover {
				visibility: hidden;
			}

			&:hover {
				.hover {
					visibility: visible;
				}
			}
		}
	}

	.rates-header-item {
		@include modules.gutter('padding');
	}

	.rates-item {
		padding: 0.25rem;
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');

		&:last-of-type {
			@include modules.gutter('padding-left', 0.25);
			@include modules.gutter('padding-right', 0.25);
		}
	}

	&__results {
		@include modules.gutter('padding');
		@include modules.gutter('grid-column-gap', 0.25);
		border-top: 1px solid modules.color_('border');
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		max-height: 300px;
		overflow-y: auto;
		width: 100%;

		&-list {
			list-style-type: none;
			margin: 0;

			.list__item {
				border-bottom: 1px solid rgba(modules.color_('border'), 0.5);
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;

				&--title {
					font-weight: 600;
					text-align: center;
				}
			}
		}

	}
}

.spot-nav {
	@include modules.gutter('margin', 0.5);
	align-items: center;
	display: flex;

	&__item {
		@include modules.fontSize(14px);
		align-items: center;
		background-color: modules.color_('primary');
		border-radius: 4px;
		color: modules.color_('primary', 'text');
		cursor: pointer;
		display: flex;
		height: 2rem;
		justify-content: center;
		margin-right: 0.5rem;
		width: 2rem;

		&:hover {
			background-color: modules.color_('primary', 'light');
		}

		&--active {
			background-color: modules.color_('secondary');
			color: modules.color_('secondary', 'text');
		}
	}

	&__title {
		@include modules.fontSize(14px);
		font-weight: bold;
		letter-spacing: 0.5px;
		margin-right: 0.5rem;
		text-transform: uppercase;
	}

	.libor-field {
		align-self: flex-end;
		margin-left: auto;
	}
}
</style>

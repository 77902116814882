<template>
	<div class="view view--main main">
		<portal-header></portal-header>
		<main-header class="main__header" />
		<!-- <router-view class="main__header" name="header" /> -->
		<router-view class="main__content" name="content" />
	</div>
</template>

<script>
import MainHeader from '@/components/MainHeader'

export default {
	name: 'ViewMain',
	components: {
		MainHeader,
	},
	mounted() {
	},
}
</script>

<style scoped lang="scss">
.main {
	background-color: modules.color_('background', 'light');

	&__header {
		background-color: modules.color_('background');
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.12);
		padding: 0 1rem;

		&-logo {
			color: modules.color_('primary');
			img {
				width: 54px;
			}
		}
	}

	&__content {
		margin-left: auto;
		margin-right: auto;
	}
}
</style>

<template>
	<form class="form form--tools form-tools" @submit.prevent.stop="">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>
		<div class="form-section">
			<div class="form-row form-row--col-4 steps-row">
				<div class="form__actions">
					<button class="button button--unelevated button--primary" @click="syncArcRates()" :disabled="isLoading">Sync ARC Rates</button>
				</div>
			</div>
		</div>

		<div class="form-section">
			<div class="form-tools__results">
				<div class="form-tools__results-list">
					<li class="list__item list__item--title">Updated ({{ results.updated.length || 0 }})</li>
					<li class="list__item"
						v-for="(item, key) in results.updated"
						:key="`updated_${key}`">
						<router-link :to="{name: 'organization', params: {orgId: item.orgId}}" target="_blank">
							{{ item.name }}
						</router-link><br />
						(<a :href="item.salesforceLink" target="salesforce">{{ item.settings.salesforceId }}</a>)
					</li>
				</div>
				<div class="form-tools__results-list">
					<li class="list__item list__item--title">Errors ({{ results.errors.length || 0 }})</li>
					<li class="list__item"
						v-for="(item, key) in results.errors"
						:key="`errors_${key}`">
						{{ item.name }}<br />
						{{ item.error }}
					</li>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

const SALESFORCE_URL = process.env.SALESFORCE_URL || process.env.VUE_APP_SALESFORCE_URL

export default {
	name: 'FormToolsSalesforce',
	components: {
		LoadingSpinner,
	},
	data: () => ({
		// isLoading: false,
		// results: {
		// 	updated: [],
		// 	errors: [],
		// },
	}),
	computed: {
		results() {
			let res = this.$store.getters['tools/salesforceSyncResults']
			res.updated = res.updated.map(r => {
				r.salesforceLink = `${SALESFORCE_URL}/lightning/r/Account/${r.settings?.salesforceId}/view`
				return r
			})

			return res
		},
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		}
	},
	methods: {
		async syncArcRates() {
			this.$store.dispatch('tools/syncArcRates')
			// this.isLoading = true
			// this.$store.dispatch('tools/syncArcRates')
			// 	.then((res) => {
			// 		res.updated = res.updated.map(r => {
			// 			r.salesforceLink = `${SALESFORCE_URL}/lightning/r/Account/${r.settings?.salesforceId}/view`
			// 			return r
			// 		})
			// 		this.results.updated = res.updated
			// 		this.results.errors = res.errors
			// 	})
			// 	.catch((error) => {
			// 		this.$notice(`ERROR: ${error.message || error}`)
			// 	})
			// 	.finally(() => {
			// 		this.isLoading = false
			// 	})
		},
	},
	metaInfo: () => ({
		title: `Salesforce Sync`,
	}),
	watch: {
		error(val) {
			if (val && Object.keys(val).length) {
				this.$notice(`ERROR: ${val}`)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.form-tools {
	&__results {
		@include modules.gutter('padding');
		@include modules.gutter('grid-column-gap', 0.25);
		border-top: 1px solid modules.color_('border');
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;

		&-list {
			list-style-type: none;
			margin: 0;

			.list__item {
				border-bottom: 1px solid rgba(modules.color_('border'), 0.5);
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;

				&--title {
					font-weight: 600;
					text-align: center;
				}
			}
		}

	}
}
</style>

<template>
	<div class="bval-reports-menu mdc-menu-surface--anchor">
		<loading-line class="bval-reports__loading" v-if="isLoading" />
		<a class="button button--secondary button--unelevated button--small" @click="toggleMenu" v-if="!isLoading">
			<span class="mdc-button__label">Create Report</span>
			<span class="mdc-button__icon">
				<ion-icon name="arrow-dropdown-circle" />
			</span>
		</a>
		<div class="mdc-menu mdc-menu-surface">
			<ul class="mdc-list mdc-list--dense mdc-list--icon-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
				<li class="mdc-list-item" role="menuitem" @click="selectReport('executiveSummary')">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__text">Executive Summary</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="selectReport('dailyQc')">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__text">Daily QC</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="selectReport('baseSummary')">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__text">Base Summary</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { v4 } from 'uuid'
import { MDCMenu } from '@material/menu'
import {Corner} from '@material/menu-surface/constants';
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'BvalReportsMenu',
	components: {
		LoadingLine,
		LoadingSpinner,
	},
	data: () => ({
		menu: null,
		isLoading: false,
	}),
	props: {
		id: {
			type: String,
			default: v4(),
		},
	},
	mounted() {
		this.menu = new MDCMenu(this.$el.querySelector('.mdc-menu'))
		this.menu.setAnchorCorner(Corner.BOTTOM_LEFT);
	},
	methods: {
		toggleMenu() {
			this.menu.open = !this.menu.open
		},
		selectReport(type) {
			this.$emit('generateReport', type)
		},
	},
}
</script>

<style scoped lang="scss">
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/icon-button";

@include icon-button.core-styles;

.bval-reports {

	&-menu {
		position: relative;
	}

	&__loading {
		bottom: 0;
		position: absolute;
	}
}

.mdc-surface-anchor {
	position: relative;
}

.mdc-list--icon-list .mdc-list-item__graphic {
	margin-right: 10px;
}

.mdc-menu-surface {
	width: 100%;
	margin: 10px 0;
}

</style>
